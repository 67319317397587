// // FASTCLICK
// $(function () {
// 	// Causes issues with form checkbox for terms (user has to double tap in case fastclick is enabled)
// 	FastClick.attach(document.body);
// 	//
// });

function validateEmail(email) {
	var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}

